/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

[dir=ltr] .vertical-layout .main-menu .navigation a.active{
	background: transparent linear-gradient(90deg, #34E0FF 0%, #34E0FF 100%) 0% 0% no-repeat padding-box !important;
	box-shadow: 0px 5px 10px #34E0FF !important;
	border-radius: 7px !important;
}

.color-card {
	background: #F6F5F8 !important;
}

.text-section {
	font: normal normal bold 72px/108px Poppins !important;
	letter-spacing: 0px;
	color: #1B1464;
  opacity: 1;
  text-align: left;
}

.text-section1 {
	text-align: left;
	font: normal normal bold 52px/50px Poppins !important;
	letter-spacing: 0px;
	color: #1B1464;
	opacity: 1;
}

.text-subtitle {
	top: 326px;
	left: 200px;
	text-align: left;
	letter-spacing: 0px;
	opacity: 1;
	font: normal normal normal 15px/20px Poppins !important;
}

.text-subtitle1 {
	text-align: left;
	font: normal normal normal 15px/15px Poppins;
	letter-spacing: 0px;
	color: #757488;
}

.tex-small {
	text-align: left;
	font: normal normal 600 15px/20px Poppins !important;
	letter-spacing: 0px;
	color: #8466FF;
}

.tex-small1 {
	text-align: left;
	font: normal normal 600 15px/20px Poppins !important;
	letter-spacing: 0px;
	color: #8466FF;
}

.card-login {
	background: #FFFFFF 0% 0% padding-box !important;
	box-shadow: 0px 0px 50px #D5E2E5B2 !important;
	border-radius: 30px;
	opacity: 1;
	width: 564px;
	height: 261px;
}
.card-login1 {
	background: #F6F5F8 0% 0% padding-box !important;
	box-shadow: 0px 0px 50px #D5E2E5B2 !important;
	opacity: 1;
}

.card-login2 {
	background: #F6F5F8 0% 0% padding-box !important;
	box-shadow: 0px 0px 50px #D5E2E5B2 !important;
	opacity: 1;
	border-radius: 0px 0px 30px 30px;
}

.card-login3 {
	background: #FFFFFF 0% 0% padding-box !important;
	box-shadow: 0px 0px 50px #D5E2E5B2 !important;
	border-radius: 30px;
	opacity: 1;
	width: 564px;
	height: 205px;
}

.text-blue {
	text-align: left;
	font: normal normal bold 16px/25px Poppins;
	letter-spacing: 0px;
	color: #8466FF;
	opacity: 1;
}

.btn.btn-primary {
  background: transparent linear-gradient(270deg, #05FFD5 19%, #8CFE3D 100%) 0%
    0% no-repeat padding-box !important;
  border-radius: 26px !important;
  border: none !important;
	color: #0F0A2B !important;
}

.btn.btn-primary:hover {
  background: #8466FF 0% 0% no-repeat padding-box !important;
	color:#FFFFFF !important;
}

.btn.btn-primary1 {
  background: transparent linear-gradient(270deg, #05FFD5 19%, #8CFE3D 100%) 0%
    0% no-repeat padding-box !important;
  border-radius: 5px !important;
  border: none !important;
	color: #0F0A2B !important;
}

.btn.btn-primary3 {
  background: #E5E0F9 !important;
  border-radius: 5px !important;
  border: none !important;
	color: #0F0A2B !important;
}

.img-login {
	top: 215px;
	background: transparent 0% 0% no-repeat padding-box;
	mix-blend-mode: darken;
	opacity: 1;
}

.text-footer {
	text-align: right !important;
	font: normal normal normal 14px/21px;
	letter-spacing: 0px;
	color: #1B1464;
	opacity: 1;
	height: 20px !important;
	width: 200px;
	display:flexbox;
}

[dir='ltr'] .vertical-layout .main-menu .navigation a.active {
	background: transparent linear-gradient(90deg, #8CFE3D 10%, #05FFD5 100%) 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 5px 10px #2cbfc865 !important;
  border-radius: 7px !important;
	color: #0F0A2B;
}


.btn.secondarybtn {
	background: #E6E4EB 0% 0% no-repeat padding-box;
	border-radius: 15px;
	opacity: 1;
}
.active .btn.btn-secondarybtn,

.btn.secondarybtn:hover {
  background: transparent linear-gradient(270deg, #A829FF 19%, #6054FF 100%) 0%
    0% no-repeat padding-box !important;
  color: #E6E4EB !important;
	border-radius: 15px !important;
}

.active .btn.btn-secondary,
.btn.btn-secondary:hover {
  background: transparent linear-gradient(270deg, #A829FF 19%, #6054FF 100%) 0%
    0% no-repeat padding-box !important;
  color: #E6E4EB !important;
	border-radius: 15px !important;
}

.active .nav-link.active {
  color: #000;
}

.nav-pill {
  font: normal normal bold 16px/16px !important;
  color: #636a6c !important;
}

.nav-pill :hover {
  color: #000000 !important;
}
.card-detail-client {
	background: #FFFFFF 0% 0% padding-box !important;
	opacity: 1;
	border-radius: 30px;
	height: 231px;
	left: -60px;
	width: 1060px;
}

.card-detail-client1 {
	background: #F6F5F8 0% 0% padding-box !important;
	opacity: 1;
	height: 80px;
	width: 1060px;
	left: -21px;
}

.text-dark {
	color: #191C1D !important;
	opacity: 1;
}

[dir='ltr'] .progress {
	background: transparent linear-gradient(90deg, #8CFE3D 10%, #05FFD5 100%) 0% 0% no-repeat padding-box !important;
	left: 865px;
	width: 300px;
}

.color-card {
	background: #E3E1E8;
}

.color-A {
	background: #05FFD534 !important;
}

.color-text {
	color: #13D8B7;
}

.card-style {
	top: 70px;
	left: 235px;
	width: 385px;
	height: 496px;
	background: #FFFFFF 0% 0% no-repeat padding-box !important;
	border-radius: 30px;
	opacity: 1;
}

.img-style {
	left: 67px ;
	width: 165px;
	height: 165px;
	background: transparent 0% 0% no-repeat padding-box !important;
	opacity: 1;
}

.style-text-card {
	font: normal normal 600 16px/0px Poppins;
	letter-spacing: 0px;
	color: #8466FF;
}

.style-text-card1 {
	font: normal normal 600 35px/0px Poppins;
	letter-spacing: 0px;
	color: #8466FF;
}

.style-text-card2 {
	font: normal normal 600 18px/0px Poppins;
	letter-spacing: 0px;
	color: #8466FF;
}

.style-text1 {
	font: normal normal normal 12px/18px Poppins;
	color: #757488;
}

.text-button {
	text-align: center;
	font: normal normal bold 14px/21px Poppins;
	letter-spacing: 0px;
	opacity: 1;

}

.style-text2 {
	font: normal normal 600 14px/21px Poppins;
	letter-spacing: 0px;
	color: #757488;
	opacity: 1;
}

.style-text-label {
	text-align: left;
	font: normal normal 600 14px/21px Poppins;
	letter-spacing: 0px;
	color: #757488;
	opacity: 1;
}

.style-text-filter {
	text-align: left;
	font: normal normal 600 18px/27px Poppins !important;
	letter-spacing: 0px;
	color: #636A6C;
	opacity: 1;
}

.text-label-table {
	text-align: left;
	font: normal normal normal 12px/18px Poppins;
	letter-spacing: 0px;
	color: #191C1D;
	opacity: 1;
}

.text-sidebar {
	text-align: left;
	font: normal normal normal 14px/21px Poppins;
	letter-spacing: 0px;
	color: #636A6C;
	opacity: 1;
}

.text-sidebar1 {
	text-align: left;
	font: normal normal normal 24px/35px Poppins;
	letter-spacing: 0px;
	color: #0F0A2B;
	opacity: 1;
}

.text-sidebar2 {
	text-align: left;
	font: normal normal 600 24px/35px Poppins;
	letter-spacing: 0px;
	color: #757488;
	opacity: 1;
}

.text-sidebar3 {
	text-align: left;
	font: normal normal 600 14px/21px Poppins;
	letter-spacing: 0px;
	color: #636A6C;
	opacity: 1;
}

.text-sidebar-checkbox {
	text-align: left;
	font: normal normal normal 14px/21px Poppins;
	letter-spacing: 0px;
	color: #191C1D;
	opacity: 1;
}

.text-back-detail {
	font: normal normal bold 16px/25px Poppins;
	letter-spacing: 0px;
	color: #191C1D;
	opacity: 1;
	left: 345px !important;
}

.btn.btn-info {
	background: #42EFCD 0% 0% no-repeat padding-box !important;
	opacity: 1;
}

.btn.btn-secondary {
  background: transparent linear-gradient(270deg, #E6E4EB 100%, #E6E4EB 100%) 0%
    0% no-repeat padding-box !important;
  border-radius: 26px !important;
  border: none !important;
	color: #757488 !important;
}

.btn.btn-secondary:hover {
  background: #8466FF 0% 0% no-repeat padding-box !important;
}

.text-color-secondary {
	text-align: center;
	font: normal normal bold 13px/21px Poppins;
	letter-spacing: 0px;
	color: #757488;
	opacity: 1;
}

.style-text-login {
	text-align: center;
	font: normal normal 600 18px/27px Poppins !important;
	letter-spacing: 0px;
	color: #0F0A2B;
	opacity: 1;
	letter-spacing: 0px;
}

.style-text-widgets-statTitle {
	text-align: left;
	font: normal normal medium 12px/12px Montserrat !important;
	letter-spacing: 0px;
	color: #757488;
	opacity: 1;
}

.style-text-widgets1-stats {
	font: normal normal bold 19px/19px Montserrat !important;
	letter-spacing: 0px;
	color: #757488;
	opacity: 1;
}

.card-widgets {
	background: #FFFFFF 0% 0% no-repeat padding-box !important;
	box-shadow: 0px 0px 10px #74838B1A;
	border-radius: 10px;
	opacity: 1;
}

.style-carddashboard {
	text-align: left;
	font: normal normal medium 24px/35px Poppins;
	letter-spacing: 0px;
	color: #757488;
	opacity: 1;
}

.text-dashboard {
	text-align: center;
	font: normal normal normal 11px/20px Poppins;
	letter-spacing: 0px;
	color: #757488;
}

.text-dashboard1 {
	text-align: left;
	font: normal normal bold 42px/62px Poppins;
	letter-spacing: 0px;
	color: #1B1464;
	opacity: 1;
}

.text-dashboard2 {
	text-align: left;
	font: normal normal normal 16px/26px Poppins;
	letter-spacing: 0px;
	color: #757488;
	opacity: 1;
}

.progress {
	background: #0F0A2B33 0% 0% no-repeat padding-box !important;
	border-radius: 100px;
	opacity: 1;
}

[dir='ltr'] .progress1 {
	background: transparent linear-gradient(90deg, #8CFE3D 10%, #05FFD5 100%) 0% 0% no-repeat padding-box !important;
	height: 6px;
}

.text-tabs {
	text-align: center;
	font: normal normal 600 22px/33px Poppins;
	letter-spacing: 0px;
	color: #757488;
	opacity: 1;
}

.text-graph {
	text-align: left;
	font: normal normal 600 18px/27px Montserrat !important;
	letter-spacing: 0px;
	color: #0F0A2B;
	opacity: 1;}

.text-carddashboard {
	text-align: left;
	font: normal normal 600 18px/27px Montserrat;
	letter-spacing: 0px;
	color: #0F0A2B;
	opacity: 1;
}

.text-carddashboard11 {
	text-align: left;
	font: normal normal 600 18px/27px Poppins;
	letter-spacing: 0px;
	color: #0F0A2B;
}

.text-carddashboard22 {
	text-align: left;
	font: normal normal normal 12px/30px Poppins;
	letter-spacing: 0px;
	color: #757488;
}

.text-carddashboard33 {
	text-align: left;
	font: normal normal normal 9px/11px Poppins;
	letter-spacing: 0px;
	color: #757488;
	opacity: 1;
}

.primary3 {
	background: #FFFFFF 0% 0% no-repeat padding-box;
	border: 1px solid #E6E4EB;
	border-radius: 26px;
	opacity: 1;
}

.text-columns {
	text-align: right;
	font: normal normal medium 16px/25px Poppins;
	letter-spacing: 0px;
	color: #1B1464;
	opacity: 1;
}

.text-columns1 {
	text-align: right;
	font: normal normal medium 16px/25px Poppins;
	letter-spacing: 0px;
	color: #0F0A2B;
	opacity: 1;
}

.color-icon {
	color: #0F0A2B !important;
}

.text-CardMembership {
	text-align: center;
	font: normal normal 600 13px/22px Poppins;
	letter-spacing: 0px;
	color: #0F0A2B;
	opacity: 1;
}

.text-CardMembership1 {
	text-align: left;
	font: normal normal normal 12px/21px Poppins;
	letter-spacing: 0px;
	color: #757488;
	opacity: 1;

	top: 0px;
	left: 0px;
	width: 274px;
	height: 38px;
}

.text-badge {
	text-align: center;
	font: normal normal 600 12px/16px Poppins;
	letter-spacing: 0px;
	color: #49CBA8;
	opacity: 1;
}

.text-CardMembership2 {
	font: normal normal 600 13px/22px Poppins;
	letter-spacing: 0px;
	color: #0F0A2B;
	opacity: 1;
}

.card-style1 {
	top: 70px;
	left: 235px;
	width: 385px;
	height: 496px;
	background: #FFFFFF 0% 0% no-repeat padding-box !important;
	border: 2px solid #8466FF;
	border-radius: 30px;
	opacity: 1;
}

.text-CardMembership3 {
	text-align: center;
	font: normal normal 600 16px/23px Poppins;
	letter-spacing: 0px;
	color: #0F0A2B;
	opacity: 1;
}

.text-CardMembership4 {
	text-align: center;
	font: normal normal normal 11px/18px Poppins;
	letter-spacing: 0px;
	color: #757488;
	opacity: 1;
}

.text-CardMembership5 {
	text-align: left;
	font: normal normal 600 14px/23px Poppins !important;
	letter-spacing: 0px;
	color: #636A6C;
	opacity: 1;
}

.text-tabs-datacenter {
	text-align: center;
	font: normal normal normal 19px/29px Poppins;
	letter-spacing: 0px;
	color: #757488;
	opacity: 1;
}

.text-table {
	text-align: left;
	font: normal normal 600 18px/27px Poppins;
	letter-spacing: 0px;
	color: #1B1464;
	opacity: 1;
}

.text-deleteMembership{
	font: normal normal bold 16px/25px Poppins;
	letter-spacing: 0px;
	color: #8466FF;
	opacity: 1;
}

.img-Membership {
	//top: 26px;
	left: 1855px !important;
	width: 160px;
	height: 50px;
}

.text-Membershipsiderbar{
	text-align: left;
	font: normal normal 600 20px/35px Poppins;
	letter-spacing: 0px;
	color: #0F0A2B;
	opacity: 1;
}

.text-Membershipsiderbar1{
	text-align: left;
	font: normal normal normal 14px/21px Poppins;
	letter-spacing: 0px;
	color: #636A6C;
	opacity: 1;
}

.text-Membershipsiderbar2{
	text-align: left;
	font: normal normal 600 14px/21px Poppins;
	letter-spacing: 0px;
	color: #636A6C;
	opacity: 1;
}

.text-Membershipsiderbar3{
	//text-align: center;
	font: normal normal bold 14px/25px Poppins;
	letter-spacing: 0px;
	color: #191C1D;
	opacity: 1;
}

.btn.btn-primary4 {
  background: transparent linear-gradient(270deg, #05FFD5 19%, #8CFE3D 100%) 0%
    0% no-repeat padding-box !important;
  border-radius: 26px !important;
  border: none !important;
	color: #0F0A2B !important;
}

.btn.btn-primary4:hover {
  background: #8466FF 0% 0% no-repeat padding-box !important;
	color: #FFFFFF !important;
}

.text-columns1 {
	text-align: right;
	font: normal normal medium 20px/25px Poppins;
	letter-spacing: 0px;
	color: #1B1464;
	opacity: 1;
}

.btn.btn-primary5 {
	background: #3EB5FF 0% 0% no-repeat padding-box;
	border-radius: 5px;
	opacity: 1;
	color: #FFFFFF;
}

.btn.btn-primary6 {
	background: #D8E7FF 0% 0% no-repeat padding-box;
	border-radius: 5px;
	opacity: 1;
	color: #062557;
}

.btn.btn-primary7 {
  background: transparent linear-gradient(270deg, #8466FF 19%, #4F31CB 100%) 0%
    0% no-repeat padding-box !important;
  border-radius: 26px !important;
  border: none !important;
	color: #FFFFFF !important;
}

.style-carddashboard8 {
	top: 0px;
	left: 0px;
	width: 559px;
	height: 220px;
	background: #FFFFFF 0% 0% no-repeat padding-box;
	box-shadow: 0px 0px 10px #74838B1A;
	border-radius: 10px;
	opacity: 1;
}

.style-carddashboard9 {
	top: 0px;
	left: 0px;
	width: 89px;
	height: 89px;
	border: 1px solid #E6E4EB;
	border-radius: 20px;
	opacity: 1;
}

.style-carddashboard12 {
	border: 1px solid #8466FF;
	border-radius: 20px;
	opacity: 1;
}

.style-carddashboard-Texto {
	text-align: center;
	font: normal normal 500 11px/19px Poppins;
	letter-spacing: 0px;
	color: #636A6C;
	opacity: 1;
}

.style-carddashboard-Texto1 {
	text-align: center;
	height: 12px;
	font: normal normal 600 22px/21px Poppins;
	letter-spacing: 0px;
	color: #060606;
	opacity: 1;
}

.style-carddashboard11 {
	top: 0px;
	left: -10px;
	width: 90px;
	height: 49px;
	border: 1px solid #E6E4EB;
	border-radius: 20px;
	opacity: 1;
}

.style-carddashboard-Texto11 {
	text-align: center;
	height: 12px;
	font: normal normal 600 22px/21px Poppins;
	letter-spacing: 0px;
	color: #060606;
	opacity: 1;
}

.style-carddashboard-Texto12 {
	text-align: center;
	height: 9px;
	font: normal normal 600 16px/21px Poppins;
	letter-spacing: 0px;
	color: #060606;
	opacity: 1;
}

.style-carddashboard-Texto22 {
	text-align: center;
	font: normal normal medium 19px/23px Montserrat;
	letter-spacing: 0px;
	color: #484671;
	opacity: 1;
}

.style-tabs {
	font: normal normal 600 16px/21px Poppins;
}

.style-carddashboard34 {
	top: 0px;
	left: 0px;
	width: 855px;
	height: 282px;
}

.style-carddashboard44 {
	top: 0px;
	left: 0px;
	width: 555px;
	height: 182px;
}

.style-carddashboard55 {
	top: 0px;
	left: 0px;
	width: 880px;
	height: 222px;
}
.style-carddashboard33 {
	top: 0px;
	left: 0px;
	width: 555px;
	height: 82px;
}

.style-carddashboard66 {
	top: 0px;
	left: 0px;
	width: 555px;
	height: 440px;
}

.style-carddashboard60 {
	top: 0px;
	left: 0px;
	width: 580px;
	height: 265px;
}

.style-carddashboard00 {
	top: 0px;
	left: 0px;
	width: 580px;
	height: 222px;
}
.text-CardMembership7 {
	text-align: reft;
	font: normal normal normal 11px/18px Poppins;
	letter-spacing: 0px;
	color: #757488;
	opacity: 1;
}


.style-icon {
	background: #757488 0% 0% no-repeat padding-box;
	opacity: 1;
}

.text-button1 {
	text-align: center;
	font: normal normal bold 14px/27px Poppins !important;
	letter-spacing: 0px;
	color: #0F0A2B;
	opacity: 1;
}

.text-toggle {
	font: normal normal 600 13px/27px Poppins;
}

.btn.btn-info1 {
	background: #3EB5FF 0% 0% no-repeat padding-box;
	border-radius: 5px;
	opacity: 1;
}

.text-sidebar1 {
	text-align: left;
	font: normal normal bold 16px/25px Poppins;
	letter-spacing: 0px;
	color: #1B1464;
	opacity: 1;
}

.text-notifications {
	text-align: left;
	font: normal normal 600 14px/21px Poppins;
	letter-spacing: 0px;
	color: #636A6C;
	opacity: 1;
}
.text-notifications1 {
	text-align: left;
	font: normal normal normal 14px/21px Poppins;
	letter-spacing: 0px;
	color: #636A6C;
	opacity: 1;
}

.btn.btn-secondary2 {
  background: #F6F5F8;
	color: #1B1464 !important;
  border-radius: 26px !important;
  border: none !important;
	width: 210px;
	height: 50px;
}

.text-sidebar-etl {
	text-align: left;
	font: normal normal 600 14px/21px Poppins;
	letter-spacing: 0px;
	color: #636A6C;
	opacity: 1;
}

.text-sidebar2 {
	text-align: left;
	font: normal normal 600 24px/35px Poppins;
	letter-spacing: 0px;
	color: #757488;
	opacity: 1;
}

.text-CerrarSesion {
	text-align: left;
	font: normal normal bold 16px/25px Poppins;
	letter-spacing: 0px;
	color: #191C1D;
	opacity: 1;
}

.style-text-sidebar {
	text-align: left;
	font: normal normal 600 18px/27px Poppins;
	letter-spacing: 0px;
	color: #757488;
	opacity: 1;
}

.style-card {
	background: #F6F5F8 0% 0% no-repeat padding-box;
	border-radius: 30px;
	opacity: 1;
}

.style-card-text {
	text-align: left;
	font: normal normal bold 14px/25px Poppins;
	letter-spacing: 0px;
	color: #1B1464;
	opacity: 1;
}

.style-text {
	width: 276px;
	height: 67px;
	text-align: left;
	font: normal normal bold 48px/72px Poppins;
	letter-spacing: 0px;
	color: #1B1464;
	opacity: 1;
}

.style-title {
	text-align: center;
	font: normal normal medium 18px/27px Poppins;
	letter-spacing: 0px;
	color: #757488;
	opacity: 1;
}

.style-text-addModule {
	text-align: center;
	font: normal normal bold 18px/22px Montserrat;
	letter-spacing: 0px;
	color: #757488;
	opacity: 1;
}

.style-text-addModule1 {
	text-align: center;
	font: normal normal medium 14px/18px Montserrat;
	letter-spacing: 0px;
	color: #757488;
	opacity: 1;
}

.style-add-module {
	background: #E6E4EB 0% 0% no-repeat padding-box;
	opacity: 1;
	border: 3px dashed #C6C5D1;
}

.style-text-button {
	font: normal normal bold 14px/21px Poppins;
	letter-spacing: 0px;
	opacity: 1;
}

.btn.btn-secondary3 {
  background: #7E6EF1;
  border-radius: 0px !important;
  border: none !important;
	width: 5px !important;
	height: 5px;
}

.btn.btn-secondary4 {
  background: #8CFE3D;
  border-radius: 0px !important;
  border: none !important;
	width: 5px !important;
	height: 5px;
}

.btn.btn-secondary5 {
  background: #05FFD5;
  border-radius: 0px !important;
  border: none !important;
	width: 5px !important;
	height: 5px;
}

.btn.btn-secondary6 {
  background: #9B8AF7;
  border-radius: 0px !important;
  border: none !important;
	width: 5px !important;
	height: 5px;
}

.btn.btn-secondary7 {
  background: #CCB2FA;
  border-radius: 0px !important;
  border: none !important;
	width: 5px !important;
	height: 5px;
}

.btn.btn-secondary8 {
  background: #F2D5FC;
  border-radius: 0px !important;
  border: none !important;
	width: 5px !important;
	height: 5px;
}
.style-card-text2 {
	text-align: left;
	font: normal normal normal 12px/15px Poppins;
	letter-spacing: 0px;
	color: #757488;
	opacity: 1;
}

.style-text-sidebar4 {
	text-align: left;
	font: normal normal 600 24px/35px Poppins;
	letter-spacing: 0px;
	color: #0F0A2B;
	opacity: 1;
}

.style-text-sidebar5 {
	text-align: left;
	font: normal normal 600 18px/27px Poppins;
	letter-spacing: 0px;
	color: #3EBEF2;
	opacity: 1;
}

.text-actions {
	text-align: left;
	font: normal normal normal 12px/18px Poppins;
	letter-spacing: 0px;
	opacity: 1;
}

.style-text-sidebar5 {
	text-align: left;
	font: normal normal bold 16px/25px Poppins;
	letter-spacing: 0px;
	color: #1B1464;
	opacity: 1;
}

.style-card-text11 {
	text-align: left;
	font: normal normal normal 24px/35px Poppins;
	letter-spacing: 0px;
	color: #0F0A2B;
	opacity: 1;
}

.style-button {
	border-radius: 15px;
	opacity: 1;
	width: 45px;
	height: 45px;
}

.style-card1 {
	background: #efeeee 0% 0% no-repeat padding-box;
	position: relative;
	top: -15px;
	height: 67px;
	left: -21px;
	width: 1060px;
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
	border-radius: 30px;
}

.remove-bg {
	filter:brightness(1.1);
	mix-blend-mode: darken;
}

.text-button2 {
	background: #e5e4e7;
	color: #E5E0F9;
	cursor: pointer;
	width: 23px;
	height: 23px;
	border-radius: 5px;
	opacity: 1;
	position: relative;
	bottom: 10px;
}

.style-ico-card3 {
	position: relative;
	top: -10px;
	width: 25px;
	height: 25px;
	border-radius: 5px;
	background: #0F0A2B;
	color: #E6E4EB;
	opacity: 1;
}

.btn.btn-secondary12 {
  background: #7E6EF1;
  border-radius: 0px !important;
  border: none !important;
	width: 5px !important;
	height: 5px;
}

.btn.btn-secondary13 {
  background: #D65E5D;
  border-radius: 0px !important;
  border: none !important;
	width: 5px !important;
	height: 5px;
	position: relative;
	left: -20px;
}

.style-ico-card1 {
	position: relative;
	top: 1px;
	width: 25px;
	height: 25px;
	border-radius: 5px;
	background: #000;
	color: #E5E0F9;
	opacity: 1;
}

.style-ico-card{
	width: 25px;
	height: 25px;
	background: #E5E0F9 0% 0% no-repeat padding-box;
	border-radius: 5px;
	opacity: 1;
	cursor: pointer;
	position: relative;
  bottom: 10px;
}

.style-button1 {
	background: #7E6EF1 0% 0% no-repeat padding-box;	height: 2mm;
	width: 16px;
	height: 11px;
	border-radius: 3px;
}

.style-card-text3 {
	text-align: left;
	font: normal normal normal 10px/18px Poppins;
	letter-spacing: 0px;
	color: #757488;
	opacity: 1;
}

.style-button2 {
	background: #D65E5D 0% 0% no-repeat padding-box;	height: 2mm;
	border-radius: 3px;
	width: 16px;
	height: 11px;
}

.style-button3 {
	background: #05FFD5 0% 0% no-repeat padding-box;	height: 2mm;
	border-radius: 3px;
	width: 16px;
	height: 11px;
}

.style-button4 {
	background: #F4B06F 0% 0% no-repeat padding-box;	height: 2mm;
	border-radius: 3px;
	width: 16px;
	height: 11px;
}

.style-card-text4 {
	left: 40px;
	position: relative;
	font: normal normal normal 12px/18px Poppins;
	letter-spacing: 0px;
	color: #757488;
	opacity: 1;
}

.style-card-text5 {
	text-align: left;
	font: normal normal normal 18px/18px Poppins;
	letter-spacing: 0px;
	color: #0f0f0f;
	opacity: 1;
	position: relative;
	left: 30px;
}

.style-button-eficiencia {
	background: #7E6EF1 0% 0% no-repeat padding-box;
	border-radius: 3px;
	width: 16px;
	height: 11px;
	opacity: 1;
}

.style-text-sidebar6 {
	text-align: left;
	font: normal normal bold 16px/25px Poppins;
	letter-spacing: 0px;
	color: #3EBEF2;
	opacity: 1;
}

.style-button-eficiencia1 {
	background: #9B8AF7 0% 0% no-repeat padding-box;
	border-radius: 3px;
	width: 16px;
	height: 11px;
	opacity: 1;
}

.style-button-eficiencia2 {
	background: #CCB2FA 0% 0% no-repeat padding-box;
	border-radius: 3px;
	width: 16px;
	height: 11px;
	opacity: 1;
}

.style-button-eficiencia3 {
	background: #F2D5FC 0% 0% no-repeat padding-box;
	border-radius: 3px;
	width: 16px;
	height: 11px;
	opacity: 1;
}

.line {
	position: relative;
	left: 63px;
	top:-130px;
	border: 1px solid #8CFE3D;
	width: 385px;
	transform: rotate(-8deg);
}
.line1 {
	position: relative;
	left: 63px;
	top:-130px;
	border: 1px solid #05FFD5;
	width: 385px;
	transform: rotate(-8deg);
}

.line2 {
	position: relative;
	left: 63px;
	top:-130px;
	border: 1px solid #05FFD5;
	width: 385px;
}

.line3 {
	position: relative;
	border: 2px solid #05FFD5;
	width: 890px;
	transform: rotate(-7deg);
}

.line4 {
	position: relative;
	left: 63px;
	top:-130px;
	border: 1px solid #05FFD5;
	width: 385px;
	transform: rotate(-15deg);
}

.style-button-graphic {
	background: #7E6EF1 0% 0% no-repeat padding-box;
	border-radius: 3px;
	width: 16px;
	height: 11px;
	opacity: 1;
}

.style-button-graphic1 {
	background: #8CFE3D 0% 0% no-repeat padding-box;
	border-radius: 3px;
	width: 16px;
	height: 11px;
	opacity: 1;
}

.style-button-graphic2 {
	background: #9B8AF7 0% 0% no-repeat padding-box;
	border-radius: 3px;
	width: 16px;
	height: 11px;
	opacity: 1;
}

.style-button-graphic3 {
	background: #CCB2FA 0% 0% no-repeat padding-box;
	border-radius: 3px;
	width: 16px;
	height: 11px;
	opacity: 1;
}

.style-button-graphic4 {
	background: #F2D5FC 0% 0% no-repeat padding-box;
	border-radius: 3px;
	width: 16px;
	height: 11px;
	opacity: 1;
}

.style-button-graphic5 {
	background: #05FFD5 0% 0% no-repeat padding-box;
	border-radius: 3px;
	width: 16px;
	height: 11px;
	opacity: 1;
}

.style-text-graphic {
	font: normal normal 600 11px/38px Poppins;
	letter-spacing: 0px;
	color: #757488;
	opacity: 1;
}

.cardRoeEbit {
	background: transparent !important;
	box-shadow: initial !important;
	position: relative;
	left: -30px;
}

.style-input1 {
	font: normal normal 16px/21px Poppins;
	letter-spacing: 0px;
	color: #0f0f0f;
	opacity: 1;
}

.style-input2 {
	font: normal normal bold 14px/21px Poppins;
	letter-spacing: 0px;
	color: #757488;
	opacity: 1;
}

.style-text-columns {
	font: normal normal 600 11px/38px Poppins;
	letter-spacing: 0px;
	color: #05FFD5;
	opacity: 1;
}

.style-text-ranking {
	font: normal normal 11px/25px Montserrat;
	letter-spacing: 0px;
	color: #6D6B7B;
	opacity: 1;
}

.style-text-ranking1 {
	font: normal normal 600 11px/11px Montserrat;
	letter-spacing: 0px;
	color: #6D6B7B;
	opacity: 1;
}

.style-text-ranking2 {
	font: normal normal 600 11px/11px Montserrat;
	letter-spacing: 0px;
	color: #05031280;
	opacity: 1;
}

.MembershipDate {
	font: normal normal bold 11px/18px Poppins;
	text-align:center;
	background: #05FFD534;
	color: #18C9AB;
	position: relative;
	border-radius: 30px;
	width: 120px;
}

.style-button-sidebar {
	background: #D8E7FF 0% 0% no-repeat padding-box;
	color: #062557;
	border-radius: 3px;
	width: 34px;
	height: 34px;
	opacity: 1;
}

.style-button-sidebar1 {
	background: #3EB5FF 0% 0% no-repeat padding-box;
	color: #FFFFFF;
	border-radius: 3px;
	width: 34px;
	height: 34px;
	opacity: 1;
}

